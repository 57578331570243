import React, { FunctionComponent } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { Locale, useProfilePageQuery } from '../../generated/graphql';
import { ProfilePage } from '../../rs-emd-ui-modules/src/components/user';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo } from '../../rs-emd-ui-modules/src/models/master/context-info.model';
import { customerService } from '../../services/customer-service';

type ProfilePageWrapperProps = {
  contextInfo: IContextInfo;
  enableMyAccount: boolean;
};

export const ProfileWrapper: FunctionComponent<ProfilePageWrapperProps> = (props: ProfilePageWrapperProps) => {
  let location = useLocation();
  const isRedirectFromForgotPassword = Boolean(JSON.parse(new URLSearchParams(location.search).get('forgotPassword') ?? '0'));
  const isReviewingProfile = Boolean(JSON.parse(new URLSearchParams(location.search).get('review') ?? '0'));

  const { loading, error, data } = useProfilePageQuery({
    ssr: false,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  }
  // if user is not logged in
  else if (!data.profilePage.isSuccess) {
    return <Redirect to='/user/login?redirectTo=/user/profile' />;
  }

  return (
    <ProfilePage
      data={data.profilePage}
      isIndia={props.contextInfo.locale === Locale.In}
      redirectFromForgotPassword={isRedirectFromForgotPassword}
      isReviewingProfile={isReviewingProfile}
      customerService={customerService}
      enableMyAccount={props.enableMyAccount}
    />
  );
};

export default ProfileWrapper;
