import React, { FunctionComponent } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { ProductDetailPage, useProductDetailPageQuery } from '../../generated/graphql';
import { ProductDetailPageComponent } from '../../rs-emd-ui-modules/src/components/product-detail-page';
import { partsListService } from '../../services/parts-list-service';
import { pdpService } from '../../services/pdp-service';
import { basketService } from '../../services/basket-service';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { productService } from '../../services/product-service';
import { pricingService } from '../../services/pricing-service';
import { plpService } from '../../services/plp-service';
import { IContextInfo, Locale } from '../../rs-emd-ui-modules/src/models/master/context-info.model';

type PDPWrapperProps = {
  contextInfo: IContextInfo;
};

export const PDPWrapper: FunctionComponent<PDPWrapperProps> = (props: PDPWrapperProps) => {
  let { stockCode } = useParams<{ stockCode: string }>();
  let location = useLocation();

  const { loading, error, data } = useProductDetailPageQuery({
    variables: { stockCode: stockCode },
    ssr: true,
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else if (!data.productDetailPage.isSuccess) {
    return <RedirectWithStatus to={`/productpage/ProductNotFound?stockcode=${stockCode}`} statusCode={404} />;
  } else if (!location.pathname.endsWith(data.productDetailPage.pdpUrl)) {
    return <RedirectWithStatus to={{ pathname: data.productDetailPage.pdpUrl, search: location.search } as Location} statusCode={301} />;
  }

  return (
    <ProductDetailPageComponent
      key={stockCode} // needed to reload component with initial state
      data={data.productDetailPage as ProductDetailPage}
      contextInfo={props.contextInfo}
      requestSubject={pdpService.requestSubject}
      responseObservable={pdpService.responseObservable}
      partsListService={partsListService}
      basketService={basketService}
      productService={productService}
      pricingService={pricingService}
      plpService={plpService}
      getStockData={productService.getStockData}></ProductDetailPageComponent>
  );
};

export default PDPWrapper;
